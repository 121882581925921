<!--
 * @Description: 首页导航
 * @Author: chengXiaoQi
 * @Date: 2022-12-23 16:33:44
 * @LastEditors: chengXiaoQi
 * @LastEditTime: 2023-01-12 21:13:31
 * @FilePath: /web-app/src/components/Header.vue
-->
<template>
  <div
    class="elv-header"
    :class="{
      'elv-header__border': props.isShowLine
    }"
  >
    <div class="elv-header-content__box">
      <!-- 主要内容 -->
      <div class="elv-header-content">
        <!-- 左侧logo -->
        <div class="elv-index-logo">
          <img src="@/assets/img/left-menu-logo-1.png" alt="" @click="onClickLogo()" />
        </div>
        <div class="elv-header-content__product">
          <el-popover
            ref="productPopoverRef"
            placement="bottom-start"
            trigger="click"
            popper-class="elv-header-content__product-popover"
            width="536px"
            :show-arrow="true"
            :offset="10"
          >
            <template #reference>
              <div class="elv-header-content__product--text">
                Product
                <SvgIcon name="product_icon" width="16" height="16" class="elv-header-content__product--icon" />
              </div>
            </template>
            <div class="elv-header-content__product-popover__box">
              <ul class="elv-header-content__product-popover__ul">
                <li v-for="(item, index) in headerMainDataAll.mainDataAll" :key="index" @click="onJump(item)">
                  <div class="elv-header-content__product-popover__ul__li-left">
                    <SvgIcon :name="item.menuIcon" width="20" height="20" fill="#B3BFCE"></SvgIcon>
                  </div>
                  <div class="elv-header-content__product-popover__ul__li-name">{{ item.menuName }}</div>
                  <div
                    class="elv-header-content__product-popover__ul__li-right"
                    :class="{ 'elv-header-content__product-popover__ul__li-right--soon': !item.childrenNoSoon.length }"
                  >
                    {{ item.childrenNoSoon.length ? item.childrenNum : 'soon' }}
                  </div>
                </li>
              </ul>
            </div>
          </el-popover>
        </div>

        <div class="elv-header-content__pricing">
          <div class="elv-header-content__pricing--text" @click="onJummPricing()">Pricing</div>
        </div>
      </div>
      <!-- 按钮部分 -->
      <div class="elv-header-button">
        <div v-if="isShowStarted" class="elv-header-button__started" @click="onGostarted()">Get Started</div>
        <div v-if="!isLogin" class="elv-header-button__login" @click="onLogin()">Login</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { HeaderMenuMainDataItemType } from '#/MenuTypes'

const router = useRouter()
const route = useRoute()

const menuGlobalStore = useMenuGlobalStore()
const { headerMainDataAll, firstPageUrl } = storeToRefs(menuGlobalStore)
const userGlobalStore = useUserGlobalStore()
// 根据userGlobalStore中的isLogin，判断“Login”按钮是否展示
const { isLogin } = storeToRefs(userGlobalStore)
const productPopoverRef = ref()
const props = defineProps({
  // 接收swiperActiveIndex，来进行页面逻辑判断处理
  swiperActiveIndex: {
    type: Number,
    default: 0
  },

  // 是否展示heade底部的线
  isShowLine: {
    type: Boolean,
    default: true
  },
  // 是否展示getStarted按钮
  isShowStarted: {
    type: Boolean,
    default: true
  },
  headerColor: {
    type: String,
    default: '#ffffff'
  }
})
const emit = defineEmits(['onJumpFirstSwiperActive'])

// 点击导航上logo
const onClickLogo = () => {
  if (route.path === '/') {
    emit('onJumpFirstSwiperActive')
  } else {
    router.replace('/')
  }
}

// 关闭Product popover
const onCloseProduct = () => {
  productPopoverRef.value?.hide()
}

// 跳转
const onJump = (itemValue: HeaderMenuMainDataItemType) => {
  if (itemValue.jumpUrl) {
    router.push({
      path: `${itemValue.jumpUrl}`
    })
    setTimeout(() => {
      onCloseProduct()
    }, 500)
  }
}

const onGostarted = () => {
  router.push({
    path: `${firstPageUrl.value}`
  })
}

const onJummPricing = () => {
  router.push({
    path: `/price`
  })
}

// 登录
const onLogin = () => {
  router.push({
    name: 'login'
  })
}
</script>

<style lang="scss" scoped>
.elv-header {
  height: 80px;
  width: 100%;

  display: flex;
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;

  position: fixed;
  top: 0;
  left: 0;
  background: v-bind('props.headerColor');

  .elv-header-content__box {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;

    .elv-header-content {
      display: flex;
      height: 100%;
      flex: 1;

      .elv-index-logo {
        width: 113px;
        height: 40px;
        padding-top: 20px;
        cursor: pointer;

        img {
          width: 113px;
          height: 40px;
        }
      }

      .elv-header-content__product {
        height: 100%;
        padding-top: 36px;
        padding-left: 50px;

        .elv-header-content__product--text {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          text-transform: capitalize;
          color: #0e1420;
          cursor: pointer;

          .elv-header-content__product--icon {
            position: relative;
            top: 2px;
          }
        }
      }

      .elv-header-content__pricing {
        height: 100%;
        padding-top: 36px;
        padding-left: 34px;

        .elv-header-content__pricing--text {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          text-transform: capitalize;
          color: #0e1420;
          cursor: pointer;
        }
      }
    }

    .elv-header-button {
      height: 100%;
      display: flex;

      .elv-header-button__started {
        height: 44px;
        line-height: 44px;
        background: #1753eb;
        border-radius: 8px;
        padding: 0 24px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        font-size: 16px;
        border: 1.5px solid #1753eb;
        color: #ffffff;
        margin-right: 16px;
        margin-top: 20px;
        cursor: pointer;
      }

      .elv-header-button__login {
        height: 44px;
        line-height: 44px;
        border-radius: 8px;
        background: #fbfbfb;
        border: 1.5px solid #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        font-size: 16px;
        padding: 0 24px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}

.elv-header__border {
  border-bottom: 1px solid #edf0f3;
  //   border-bottom: 1px solid red;
}
</style>
<style lang="scss">
.elv-header-content__product-popover {
  padding: 0 !important;
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.05), 0px 0px 33.4221px rgba(0, 0, 0, 0.0503198) !important;
  border-radius: 16px !important;
  box-sizing: border-box;
  overflow: visible !important;

  .elv-header-content__product-popover__box {
    width: 100%;
    padding: 15px 20px !important;
    box-sizing: border-box;

    .elv-header-content__product-popover__ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 316px;
      align-content: space-between;

      li {
        width: 240px;
        margin-bottom: 5px;
        margin-top: 5px;
        height: 69px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #f9fafb;
        }

        .elv-header-content__product-popover__ul__li-left {
          width: 45px;
          height: 45px;
          margin-left: 12px;
          background: #f9fafb;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .elv-header-content__product-popover__ul__li-name {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 16px;
          height: 69px;
          line-height: 69px;
          color: #0e0f11;
          margin-left: 11px;
        }

        .elv-header-content__product-popover__ul__li-right {
          padding: 0 8px;
          height: 24px;
          border: 1px solid #e4e7eb;
          border-radius: 4px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #d0d4d9;
          margin-left: 8px;
          box-sizing: border-box;
        }

        .elv-header-content__product-popover__ul__li-right--soon {
          line-height: 20px;
        }
      }
    }
  }
}
</style>
