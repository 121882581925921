<template>
  <!-- 页脚height:530px -->
  <footer class="elv-index-footer-main">
    <div class="elv-index-footer-top">
      <div class="elv-index-footer-top-main">
        <div class="elv-index-footer-top-main__title">EXPLORE MORE FINANCIAL STATEMENTS</div>
        <div class="elv-index-footer-top-main__sales" @click="onTalkSalesClick()">Talk to sales</div>
      </div>
    </div>
    <div class="elv-index-footer-bottom">
      <div class="elv-index-footer-bottom-main">
        <div class="elv-index-footer-bottom__content">
          <div style="width: 212px">
            <img
              src="@/assets/img/logo-white.png"
              alt=""
              class="elv-index-footer-bottom__content-logo"
              @click="onLogoClick()"
            />
          </div>
          <div style="width: 189px">
            <div class="elv-index-footer-bottom__content-menu-title">Product</div>
            <div>
              <li v-for="(item, i) in productMenuList" :key="i + item.menuName" @click="onNodeClick(item)">
                <div class="elv-index-footer-bottom__content-menu-normal">{{ item.menuName }}</div>
              </li>
            </div>
          </div>
          <div style="width: 287px">
            <div class="elv-index-footer-bottom__content-menu-title">Company</div>
            <div>
              <li v-for="(item, i) in companyMenuList" :key="i" @click="onNodeClick(item)">
                <div class="elv-index-footer-bottom__content-menu-normal">{{ item.name }}</div>
              </li>
            </div>
          </div>

          <div class="elv-index-footer-bottom__content-started">
            <div class="elv-index-footer-bottom__content-started__experience">Experience it？</div>
            <div class="elv-index-footer-bottom__content-started__get" @click="onStartedClick()">Get started</div>
            <div class="elv-index-footer-bottom__content-started__talk" @click="onTalkSalesClick()">Talk to sales</div>
          </div>
        </div>
        <div class="elv-index-footer-bottom__copy-right">
          <div>© 2022 Elven.All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { HeaderMenuMainDataItemType } from '#/MenuTypes'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const route = useRoute()
const router = useRouter()

const menuGlobalStore = useMenuGlobalStore()
const { headerMainDataAll, firstPageUrl } = storeToRefs(menuGlobalStore)
const companyMenuList = ref<any[]>([
  { name: 'About', jumpUrl: '/about' },
  { name: 'Contact Sales', jumpUrl: '/price' },
  { name: 'Careers', jumpUrl: '/careers' }
])

const productMenuList = ref<HeaderMenuMainDataItemType[]>(headerMainDataAll.value.mainDataAll)
const emit = defineEmits(['onJumpFirstSwiperActive'])

const onLogoClick = () => {
  if (route.path === '/') {
    emit('onJumpFirstSwiperActive')
  } else {
    router.replace('/')
  }
}

const onNodeClick = (data: any) => {
  router.push(data.jumpUrl)
}

const onTalkSalesClick = () => {
  window.open('https://www.elven.com/contacts')
}

const onStartedClick = () => {
  router.push({
    path: `${firstPageUrl.value}`
  })
}
</script>

<style lang="scss" scoped>
.elv-index-footer-main {
  width: 100%;
  min-width: 1440px;

  .elv-index-footer-top {
    width: 100%;
    background: $elv-theme-color;

    .elv-index-footer-top-main {
      background: url('@/assets/img/footer_bg.png') no-repeat;
      background-size: 1440px 260px;
      box-sizing: border-box;
      width: 1440px;
      margin: 0 auto;
      padding-top: 56px;
      padding-bottom: 56px;
      height: 260px;

      .elv-index-footer-top-main__title {
        margin-left: 286px;
        width: 500px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        font-size: 30px;
        color: $elv-color-FFFFFF;
        line-height: 40px;
      }

      .elv-index-footer-top-main__sales {
        margin-left: 286px;
        background: #ffffff;
        border-radius: 30px;
        line-height: 48px;
        text-align: center;
        color: $elv-theme-color;
        width: 140px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        font-size: 16px;
        margin-top: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .elv-index-footer-bottom {
    background: $elv-theme-base-text-color;
    width: 100%;

    .elv-index-footer-bottom-main {
      width: 100%;

      .elv-index-footer-bottom__content {
        background: $elv-theme-base-text-color;
        width: 1440px;
        margin: 0 auto;
        height: 223px;
        display: flex;
        padding-left: 286px;
        box-sizing: border-box;

        .elv-index-footer-bottom__content-logo {
          width: 56px;
          height: 20px;
          margin-top: 44px;

          &:hover {
            cursor: pointer;
          }
        }

        .elv-index-footer-bottom__content-started {
          width: 206px;

          .elv-index-footer-bottom__content-started__experience {
            height: 15px;
            line-height: 15px;
            margin-top: 44px;
            color: $elv-color-838d95;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 12px;
          }

          .elv-index-footer-bottom__content-started__get {
            height: 40px;
            line-height: 40px;
            margin-top: 11px;
            background: $elv-theme-color;
            border-radius: 30px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            color: $elv-color-FFFFFF;

            &:hover {
              cursor: pointer;
            }
          }

          .elv-index-footer-bottom__content-started__talk {
            height: 40px;
            line-height: 40px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
            font-size: 12px;
            text-align: center;
            color: $elv-theme-quaternary-text-color;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .elv-index-footer-bottom__content-menu-normal {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          color: $elv-theme-quaternary-text-color;
          font-size: 12px;
          line-height: 11px;
          margin-bottom: 8px;

          &:hover {
            color: $elv-color-838d95;
            cursor: pointer;
          }
        }

        .elv-index-footer-bottom__content-menu-title {
          margin-bottom: 12px;
          margin-top: 50px;
          color: $elv-theme-tertiary-text-color;
        }
      }

      .elv-index-footer-bottom__copy-right {
        border-top: 1px solid rgba(242, 242, 242, 0.1);
        width: 100%;

        div {
          width: 1440px;
          margin: 0 auto;
          padding-left: 286px;
          box-sizing: border-box;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 47px;
          color: $elv-theme-quaternary-text-color;
          background: $elv-theme-base-text-color;
        }
      }
    }
  }
}
</style>
